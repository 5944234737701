<template>
  <div>
    <div v-show="showMenu" class="text-dark-dark-gray p-5 bg-white">
      <div class="md:hidden">
        <p class="text-2xl font-bold pb-5">Insider Dashboard</p>
        <div
          @click="clickIncomeOverview"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Income Overview</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <hr />
        <div
          @click="clickProducts"
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Products</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
        <hr />
        <div
          @click=""
          class="py-2 text-lg flex flex-row justify-between items-center"
        >
          <p>Customers</p>
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            :color="'#30C3E3'"
          />
        </div>
      </div>
    </div>
    <div class="hidden md:inline px-4 lg:px-0">
      <p class="text-2xl font-bold pb-5">Insider Dashboard</p>
      <div class="flex flex-row gap-5">
        <div class="cursor-pointer" @click="clickProducts">
          <p :style="getProductStyling">Products</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ViewProducts from '@/views/Insider/Products/ViewProducts.vue';

export default {
  name: 'InsiderDashboard',
  components: {
    ViewProducts
  },
  data() {
    return {
      showMenu: true,
      showOptions: false,
      showProducts: false,
    };
  },
  methods: {
    clickInsiderDashboard(){
      this.showMenu = true;
      this.showOptions = false;
      this.showProducts = false;
    },
    clickProducts() {
      this.$router.push({ name: 'ViewProducts' })
    },
    clickIncomeOverview(){
      this.$router.push({ name: 'IncomeOverview' })
    }
  },
  computed: {
    getProductStyling() {
      return this.showProfileSettings
        ? 'color: #30C3E3; font-weight: 700; text-decoration: underline; text-underline-offset: 4px;'
        : '';
    },
  }
};
</script>
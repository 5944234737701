<template>
  <div
    :style="isOpen ? 'transition: 0s; width: 100%;' : 'transition: 0s; width: 0px'"
    class="fixed top-0 bottom-0 left-0 right-0 z-50 bg-modal h-full"
  >
    <div
      v-click-outside="close"
      :style="isOpen ? 'transition: 0.5s; width: 300px;' : 'transition: 0.5s; width: 0px'"
      class="fixed bg-white top-0 left-0 overflow-x-hidden h-full z-10"
    >
      <div class="bg-blue grid justify-center items-center pb-5 w-full pt-12">
        <div class="flex flex-row items-end justify-end pt-4 pb-4">
          <font-awesome-icon @click="close" class="text-xl text-white" :icon="['fas', 'xmark']" />
        </div>
        <router-link :to="{ name: 'Profile' }">
          <div class="w-20 h-20 rounded-full border-2 border-white overflow-hidden m-auto" @click="close">
            <img :src="profilePicture" alt="Avatar" class="w-20 h-20" />
          </div>
        </router-link>
        <p class="text-[#FFFFFF] text-[16px] font-[500] mt-2 capitalize">Happy Slydeposting, {{ userFirstName }}!</p>
      </div>
      <div class="px-6 py-6">
        <CreateButton @clicked="close" />

        <div id="nav" class="pt-4 flex flex-col gap-2 text-[#555]">
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'house']" />
            <router-link :to="{ name: 'Newsfeed' }">Home</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'list-ul']" />
            <router-link :to="{ name: 'Lists' }">Lists</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'arrow-up']" />
            <router-link :to="{ name: 'Trending' }">Trending</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'search']" />
            <router-link :to="{ name: 'Search' }">Search</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'user-group']" />
            <router-link :to="{ name: 'Community' }">My Community</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'bell']" />
            <router-link :to="{ name: 'Notifications' }">
              <div class="relative">
                <p>Notifications</p>
                <div class="absolute top-0 left-[86px] w-2 h-2 bg-teal rounded-full" v-if="hasNotifications"></div>
              </div>
            </router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'pencil']" />
            <router-link :to="{ name: 'Drafts' }">Drafts</router-link>
          </div>
          <div v-if='isInsiderEnabled === `true` && isInsider' @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['far', 'gem']" />
            <router-link :to="{ name: 'Insider' }">Insider</router-link>
          </div>
          <div v-if='isInsiderEnabled === `true` && isCustomer' @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'box']" />
            <router-link :to="{ name: 'Payments' }">Products</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'gear']" />
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
          </div>
          <div @click="close" class="flex flex-row gap-2 items-center justify-start">
            <font-awesome-icon :icon="['fas', 'life-ring']" />
            <router-link :to="{ name: 'Help', query: { page: 'help' } }">Help</router-link>
          </div>
        </div>
        <div @click="signOut" class="flex items-center text-[16px] font-[500] gap-5 px-6 py-2 absolute bottom-2">
          <font-awesome-icon :icon="['fas', 'right-from-bracket']" />
          <div>Sign Out</div>
        </div>
      </div>
    </div>
    <AddListModal ref="addListModal" />
  </div>
</template>

<script>
import store from '@/store/store';
import AddListModal from '@/components/Modals/AddListModal.vue';
import { mapGetters } from 'vuex';
import CreateButton from '@/components/Buttons/CreateButton.vue';
import router from '@/router';

export default {
  name: 'ProfileOptionsSidebar',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    signOutData: {
      type: Object,
      required: true,
    },
    closeProfileMenuSidebarFunc: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userFirstName: 'getFirstName',
      profilePicture: 'getProfilePictureUrl',
      hasNotifications: 'getHasNotifications',
      isInsider: 'getIsInsider',
      isCustomer: 'getIsCustomer'
    }),
    isInsiderEnabled() {
      let isEnabled = process.env.VUE_APP_IS_INSIDER_ENABLED;
      return isEnabled;
    },
  },
  components: {
    AddListModal,
    CreateButton,
  },
  data() {
    return {
      openCreateDropdown: false,
    };
  },
  methods: {
    openNewListModal() {
      this.$emit('close');
      this.$refs.addListModal.openModal();
    },
    close() {
      this.$emit('close');
    },
    toggleCreateDropdown() {
      this.openCreateDropdown = !this.openCreateDropdown;
    },
    closeCreateDropdown() {
      this.openCreateDropdown = false;
    },
    async signOut() {
      try {
        await store.dispatch('authStoreModule/signOut');
        store.commit('clearLoggedInUserState');
        store.commit('authStoreModule/setUser', null);
        await router.push({ name: 'Login' });
      } catch (error) {
        console.error('error signing out: ', error);
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="backToDashboard">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Dashboard
      </button>
    </div>
    <p class="text-2xl pb-2 text-dark-gray">Your Products</p>
    <div>
      <div @click='showSubscriptions = !showSubscriptions' :class='showSubscriptions ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center gap-2 border-moonstone'>
        <p class='text-lg font-medium'>Subscriptions</p>
        <div>
          <font-awesome-icon
            v-show="this.showSubscriptions === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showSubscriptions === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showSubscriptions' class='flex flex-col'>
        <div v-if='subscriptionProducts.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No subscriptions yet</p>
        </div>
        <div :class='showSubscriptions ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='product in subscriptionProducts' :key='product.id'>
          <SubscriptionProduct
            v-for='price in product.prices'
            :key='price.id'
            :price='price.price'
            :price-type='price.priceType'
            @click="editSubscriptionProduct(product, price)"
          />
        </div>
      </div>
    </div>
    <div>
      <div @click='showLists = !showLists' :class='showLists ? `rounded-t-xl border-2 border-b-0` : `rounded-xl border-2`' class='p-2 flex flex-row justify-between items-center border-moonstone'>
        <p class='text-lg font-medium'>Lists</p>
        <div>
          <font-awesome-icon
            v-show="this.showLists === false"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-down']"
          />
          <font-awesome-icon
            v-show="this.showLists === true"
            color="#015D79"
            class="cursor-pointer text-lg md:text-sm"
            :icon="['fas', 'chevron-up']"
          />
        </div>
      </div>
      <div v-if='showLists' class='flex flex-col'>
        <div v-if='listProducts.length <= 0' class='rounded-b-xl border-2 border-moonstone p-2'>
          <p>No list products yet</p>
        </div>
        <div :class='showLists ? `last:rounded-b-xl last:border-b-2 border-b-0` : `rounded-xl`' class='border-2 border-moonstone' v-for='product in listProducts' :key='product.id'>
          <ListProduct
            v-if='product.productType === `LIST_PURCHASE`'
            :title='product.contentName'
            :price='product.prices[0].price'
            @click="editListProduct(product)"
          />
        </div>
      </div>
    </div>
    <div class='pt-2'>
      <button class='w-full rounded-lg' @click="goToCreateProduct">New product</button>
    </div>
  </div>
</template>

<script>
import CreateProduct from '@/components/Insider/Products/CreateProduct.vue';
import { mapGetters } from 'vuex';
import SubscriptionProduct from '@/components/Insider/Products/SubscriptionProduct.vue';
import ListProduct from '@/components/Insider/Products/ListProduct.vue';

export default {
  name: 'ViewProduct',
  components: {
    CreateProduct,
    SubscriptionProduct,
    ListProduct
  },
  data(){
    return {
      showSubscriptions: false,
      showLists: false,
      showCreateProduct: false,
    }
  },
  computed: {
    ...mapGetters({
      products: 'insiderStoreModule/getProducts',
    }),
    subscriptionProducts(){
      return this.products.filter(item => item.productType === 'INSIDER_SUBSCRIPTION');
    },
    listProducts(){
      return this.products.filter(item => item.productType === 'LIST_PURCHASE');
    },
  },
  methods: {
    goToCreateProduct(){
      this.$router.push({ name: 'CreateProduct' });
    },
    backToDashboard(){
      this.$router.push({ name: 'Insider' });
    },
    editListProduct(product){
      this.$store.commit('editProductStoreModule/setContentId', product.contentId);
      this.$store.commit('editProductStoreModule/setContentName', product.contentName);
      this.$store.commit('editProductStoreModule/setProductId', product.id);
      this.$store.commit('editProductStoreModule/setPriceId', product.prices[0].id);
      this.$store.commit('editProductStoreModule/setPrice', product.prices[0].price / 100.0);
      this.$store.commit('editProductStoreModule/setProductType', product.productType);
      this.$store.commit('editProductStoreModule/setPriceType', product.prices[0].priceType);
      this.$router.push({ name: 'EditProduct' });
    },
    editSubscriptionProduct(product, price){
      this.$store.commit('editProductStoreModule/setContentId', product.contentId);
      this.$store.commit('editProductStoreModule/setContentName', product.contentName);
      this.$store.commit('editProductStoreModule/setProductId', product.id);
      this.$store.commit('editProductStoreModule/setPriceId', price.id);
      this.$store.commit('editProductStoreModule/setPrice', price.price / 100.0);
      this.$store.commit('editProductStoreModule/setProductType', product.productType);
      this.$store.commit('editProductStoreModule/setPriceType', price.priceType);
      this.$router.push({ name: 'EditProduct' });
    }
  },
  mounted() {
    this.$store.dispatch('insiderStoreModule/getProducts');
  },
  unmounted() {
    this.$store.commit('insiderCreateProductStoreModule/clear')
  },
}
</script>
<template>
  <div class="bg-blue">
    <div class="flex justify-center pt-8">
      <img class="w-20 h-20 rounded-full" :src="profilePictureUrl" />
    </div>
    <div class="capitalize flex flex-row justify-center items-center gap-2 pt-5 text-2xl font-bold text-white">
      <font-awesome-icon v-if='isInsider' class='text-lg text-yellow-400' :icon="['far', 'gem']" />
      <p>{{ firstName }} {{ lastName }}</p>
    </div>
    <div
      v-if="this.username != null && this.username !== ''"
      class="uppercase pt-2 flex justify-center text-xs font-bold text-white"
    >
      <p>@{{ username }}</p>
    </div>
    <div class="flex justify-center pt-2 px-4 text-sm text-white">
      <ReadMoreText class="md:w-96" :text="this.bio" />
    </div>
    <div class="flex justify-center pt-3">
      <button @click.prevent="changeRelationshipStatus" class="uppercase h-9 w-32 text-xs bg-dark-orange border-none">
        {{ followText }}
      </button>
    </div>
    <div class="flex justify-center pt-3">
      <p v-if="totalFollowers !== 1" class="uppercase text-xs text-white font-bold">{{ totalFollowers }} followers</p>
      <p v-else class="uppercase text-xs text-white font-bold">{{ totalFollowers }} follower</p>
    </div>
    <div class="flex flex-row pt-4 justify-center">
      <div
        @click="this.selectLikes()"
        :style="this.likesSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center text-white rounded-t-2xl"
      >
        <p class="text-3xl pt-2">{{ totalPublicPostsLikedByUser }}</p>
        <p>Likes</p>
        <p class="font-bold text-3xl text-blue">|</p>
      </div>
      <div
        @click="this.selectSlydeposts()"
        :style="this.slydepostSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center bg-white rounded-t-2xl gap-0 text-blue"
      >
        <div class="text-3xl pt-2">{{ totalPublicUserSlydeposts }}</div>
        <div>Slydeposts</div>
        <div class="font-bold text-3xl text-blue">|</div>
      </div>
      <div
        @click="this.selectLists()"
        :style="this.listSelectedStyle()"
        class="cursor-pointer w-28 flex flex-col justify-center items-center text-white rounded-t-2xl"
      >
        <p class="text-3xl pt-2">{{ this.totalLists }}</p>
        <p>Lists</p>
        <p class="font-bold text-3xl text-blue">|</p>
      </div>
    </div>
    <div
      class="bg-ice flex flex-row gap-2 items-center justify-center pt-10"
      v-if="visibility === 'PRIVATE' && relationshipBetweenLoggedInUserAndFriend.status !== 'FOLLOWING'"
    >
      <p>This account is private</p>
      <font-awesome-icon :icon="['fas', 'lock']" />
    </div>
    <div v-else>
      <div class="bg-ice">
        <div v-if="this.view === 'likes'" class="flex flex-col gap-4">
          <div
            v-for="slydepost in postsLikedByUser"
            :key="slydepost.id"
            class="first:border-t-0 border border-light-gray"
          >
            <SlydepostLikedByUser
              :id="slydepost.id"
              :title="slydepost.title"
              :description="slydepost.description"
              :aspects="slydepost.tags"
              :user-id="slydepost.userId"
              :created-at="slydepost.createdAt"
              :user-first-name="slydepost.userFirstName"
              :user-last-name="slydepost.userLastName"
              :category="slydepost.category"
              :subcategory="slydepost.subcategory"
              :comments="slydepost.comments"
              :images="slydepost.presignedMediaUrls"
              :address="slydepost.address"
              :link="slydepost.link"
              :meta-rating="slydepost.metaRating"
              :display-comments="true"
              :media="slydepost.media"
              :likes="slydepost.likes"
              :profile-picture-url="slydepost.userProfilePhotoUrl"
            />
          </div>
          <InfiniteLoading @infinite="loadMoreLikedPosts" />
        </div>
        <div v-else-if="view === 'slydeposts'" class="flex flex-col gap-4">
          <div
            v-for="slydepost in userSlydeposts"
            :key="slydepost.id"
            class="first:border-t-0 border border-light-gray"
          >
            <SlydepostCreatedByUser
              :id="slydepost.id"
              :title="slydepost.title"
              :description="slydepost.description"
              :aspects="slydepost.tags"
              :user-id="slydepost.userId"
              :created-at="slydepost.createdAt"
              :user-first-name="slydepost.userFirstName"
              :user-last-name="slydepost.userLastName"
              :category="slydepost.category"
              :subcategory="slydepost.subcategory"
              :comments="slydepost.comments"
              :images="slydepost.presignedMediaUrls"
              :address="slydepost.address"
              :link="slydepost.link"
              :meta-rating="slydepost.metaRating"
              :display-comments="true"
              :media="slydepost.media"
              :likes="slydepost.likes"
              :profile-picture-url="slydepost.userProfilePhotoUrl"
            />
          </div>
          <InfiniteLoading @infinite="loadMorePosts" />
        </div>
        <div v-else-if="view === 'lists'">
          <div class="flex justify-end items-center pt-2 px-4">
            <div class="mr-2" @click="gridView = 'list'">
              <font-awesome-icon class="text-2xl" :icon="['fas', 'pause']" rotation="90" />
            </div>
            <div @click="gridView = 'grid'">
              <font-awesome-icon class="text-2xl" :icon="['fas', 'grip']" />
            </div>
          </div>
          <div
            :class="
              gridView === 'list'
                ? 'grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 px-4 lg:px-0 pt-2 pb-4'
                : 'grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-0 pt-2 pb-4'
            "
          >
            <ListCard
              v-for="list in lists"
              :key="list.id"
              :id="list.id"
              :cover="list.presignedUrl"
              :title="list.title"
              :follow-count="list.totalFollows"
              :post-count="list.totalPosts"
              :profile-picture-url="list.userProfilePicturePresignedUrl"
              :status='list.status'
            />
            <InfiniteLoading @infinite="loadMoreLists" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlydepostCreatedByUser from '@/components/ViewSlydepost/Wrappers/SlydepostCreatedByUser.vue';
import SlydepostLikedByUser from '@/components/ViewSlydepost/Wrappers/SlydepostLikedByUser.vue';
import InfiniteLoading from 'v3-infinite-loading';
import store from '@/store/store';
import ReadMoreText from '@/components/Utility/ReadMoreText.vue';
import ListCard from '@/components/ViewLists/ListCardV2.vue';

export default {
  name: 'UserProfile',
  data() {
    return {
      userId: '',
      view: 'slydeposts',
      slydepostSelected: true,
      likesSelected: false,
      listSelected: false,
      gridView: 'grid',
    };
  },
  components: {
    SlydepostLikedByUser,
    InfiniteLoading,
    ReadMoreText,
    ListCard,
    SlydepostCreatedByUser,
  },
  computed: {
    ...mapGetters({
      firstName: 'userStoreModule/getPublicProfileFirstName',
      lastName: 'userStoreModule/getPublicProfileLastName',
      username: 'userStoreModule/getPublicUsername',
      bio: 'userStoreModule/getPublicBio',
      profilePictureUrl: 'userStoreModule/getPublicProfileProfilePictureUrl',
      postsLikedByUser: 'userStoreModule/getPublicLikedPosts',
      totalPublicPostsLikedByUser: 'userStoreModule/getTotalPublicLikedPosts',
      userSlydeposts: 'userStoreModule/getPublicUserProfileSlydeposts',
      totalPublicUserSlydeposts: 'userStoreModule/getTotalPublicPosts',
      relationshipBetweenLoggedInUserAndFriend: 'relationshipStoreModule/getRelationshipBetweenLoggedInUserAndFriend',
      visibility: 'userStoreModule/getPublicUserProfileVisibility',
      totalFollowers: 'relationshipStoreModule/getPublicUserTotalFollowers',
      lists: 'userStoreModule/getLists',
      totalLists: 'userStoreModule/getListSize',
      isInsider: 'userStoreModule/getIsInsider'
    }),
    relationshipStatus() {
      if (this.relationshipBetweenLoggedInUserAndFriend == null) {
        return null;
      }
      return this.relationshipBetweenLoggedInUserAndFriend.status;
    },
    followText() {
      if (this.relationshipStatus == null) {
        return 'Follow';
      } else if (this.relationshipBetweenLoggedInUserAndFriend.status === 'REQUESTED') {
        return 'Requested';
      } else if (
        this.relationshipBetweenLoggedInUserAndFriend.status === 'FOLLOWING' ||
        this.relationshipStatus === 'FOLLOWING'
      ) {
        return 'Following';
      } else {
        return 'Follow';
      }
    },
  },
  methods: {
    changeRelationshipStatus() {
      // no relationship exists, need to create one
      if (this.relationshipStatus == null) {
        this.$store.dispatch('relationshipStoreModule/followUser', this.userId);
      }
    },
    selectSlydeposts() {
      this.view = 'slydeposts';
      this.slydepostSelected = true;
      this.likesSelected = false;
      this.listSelected = false;
    },
    selectLikes() {
      this.view = 'likes';
      this.likesSelected = true;
      this.slydepostSelected = false;
      this.listSelected = false;
    },
    selectLists() {
      this.view = 'lists';
      this.listSelected = true;
      this.likesSelected = false;
      this.slydepostSelected = false;
    },
    slydepostSelectedStyle() {
      if (this.slydepostSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    likesSelectedStyle() {
      if (this.likesSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    listSelectedStyle() {
      if (this.listSelected) {
        return 'background: white; color: #015D79';
      } else {
        return 'background: #015D79; color: white';
      }
    },
    loadMorePosts() {
      // don't load MORE slydeposts if none have been loaded yet or there are no slydeposts to load
      if (this.userSlydeposts == null) {
        return;
      }
      let payload = {
        index: this.userSlydeposts.length,
        userId: this.userId,
      };
      this.$store.dispatch('userStoreModule/loadMorePublicUserProfileSlydeposts', payload);
    },
    loadMoreLikedPosts() {
      if (this.postsLikedByUser == null) {
        return;
      }
      let payload = {
        index: this.postsLikedByUser.length,
        userId: this.userId,
      };
      this.$store.dispatch('userStoreModule/loadMorePostsLikedByUser', payload);
    },
    loadMoreLists() {
      this.$store.dispatch('userStoreModule/getUsersLists', this.userId);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.userId = this.$route.params.userId;
    this.$store.dispatch('userStoreModule/getPublicUserProfile', this.$route.params.userId);
    const likesIndex = this.postsLikedByUser == null ? 0 : this.postsLikedByUser.length;
    let likesPayload = {
      userId: this.$route.params.userId,
      index: likesIndex,
    };
    this.$store.dispatch('userStoreModule/loadMorePostsLikedByUser', likesPayload);
    const postsIndex = this.userSlydeposts == null ? 0 : this.userSlydeposts.length;
    let postsPayload = {
      userId: this.$route.params.userId,
      index: postsIndex,
    };
    this.$store.dispatch('userStoreModule/loadMorePublicUserProfileSlydeposts', postsPayload);
    this.$store.dispatch(
      'relationshipStoreModule/getRelationshipBetweenLoggedInUserAndFriend',
      this.$route.params.userId
    );
    this.$store.dispatch('relationshipStoreModule/getTotalNumberOfFollowers', this.$route.params.userId);
    this.$store.dispatch('userStoreModule/getUsersLists', this.$route.params.userId);
  },
  unmounted() {
    this.$store.commit('userStoreModule/navigateAwayFromUserProfile');
  },
};
</script>

<template>
  <div class="px-4 lg:px-0 lg:pt-0 flex flex-col gap-2">
    <div class="pt-4 md:hidden">
      <button @click.prevent="backToDashboard">
        <font-awesome-icon :icon="['fas', 'chevron-left']" color="white" />
        Dashboard
      </button>
    </div>
    <div>
      <p class="text-2xl pb-2 text-dark-gray">Income Overview</p>
    </div>
    <div>
      <div>
        <p>Total monthly subscriptions: </p>
        <p>Value:</p>
      </div>
      <div>
        <p>Total yearly subscriptions: </p>
        <p>Value:</p>
      </div>
      <div>
        <p>Total list purchases: </p>
        <p>Value:</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncomeOverview',
  methods: {
    backToDashboard(){
      this.$router.push({ name: 'Insider' });
    }
  }
}
</script>
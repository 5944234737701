<template>
  <div v-if="isLoggedIn">
    <template v-if="this.$route.meta.hideLayout">
      <router-view />
    </template>
    <template v-else>
      <div>
        <NavigationBar />
      </div>
      <div class="grid-container-sm lg:grid-container gap-2 lg:gap-4 pt-12">
        <div class="grid-menu hidden lg:flex flex-auto flex-col justify-start bg-ice max-w-xs lg:min-w-min">
          <CreateButton class="w-52" />
          <div id="nav" class="flex flex-col gap-2 pt-6 font-bold text-blue">
            <router-link :to="{ name: 'Newsfeed' }">Home</router-link>
            <router-link :to="{ name: 'Lists' }">Lists</router-link>
            <router-link :to="{ name: 'Trending' }">Trending</router-link>
            <router-link :to="{ name: 'Search' }">Search</router-link>
            <router-link :to="{ name: 'Community' }">My Community</router-link>
            <router-link :to="{ name: 'Notifications' }">
              <div class="relative">
                <p>Notifications</p>
                <div class="absolute top-0 left-24 w-2 h-2 bg-teal rounded-full" v-if="hasNotifications"></div>
              </div>
            </router-link>
            <router-link :to="{ name: 'Drafts' }">Drafts</router-link>
            <router-link v-if='isInsiderEnabled === `true` && isInsider' :to="{ name: 'Insider' }">Insider</router-link>
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
            <router-link :to="{ name: 'Help', query: { page: 'help' } }">Help</router-link>
          </div>
        </div>
        <div class="grid-content bg-ice flex flex-col lg:pt-0">
          <router-view :key="$route.fullPath" />
        </div>
        <div
          v-if="isTrendingEnabled === 'true'"
          class="hidden lg:inline-block grid-friends lg:flex flex-col gap-2 bg-ice"
        >
          <template v-for="componentName in trendingComponentsOrder" :key="componentName">
            <component
              :is="trendingComponents.filter((tc) => tc.name === componentName)[0].component"
              :truncated="true"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from '@/components/Header/NavigationBar.vue';
import store from '@/store/store';
import CreateButton from '@/components/Buttons/CreateButton.vue';
import { mapGetters } from 'vuex';
import Trending from '@/components/Trending/Sidebar/TrendingLists.vue';
import TrendingUsers from '@/components/Trending/Sidebar/TrendingUsers.vue';
import TrendingSlydeposts from '@/components/Trending/Sidebar/TrendingSlydeposts.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    CreateButton,
    Trending,
    TrendingUsers,
    TrendingSlydeposts,
  },
  data() {
    return {
      showModal: false,
      trendingComponents: [
        { name: 'Trending', component: Trending },
        { name: 'TrendingUsers', component: TrendingUsers },
        { name: 'TrendingSlydeposts', component: TrendingSlydeposts },
      ],
    };
  },
  computed: {
    ...mapGetters({
      hasNotifications: 'getHasNotifications',
      isInsider: 'getIsInsider'
    }),
    isInsiderEnabled() {
      let isEnabled = process.env.VUE_APP_IS_INSIDER_ENABLED;
      return isEnabled;
    },
    isTrendingEnabled() {
      let isEnabled = process.env.VUE_APP_IS_TRENDING_SIDEBAR_ENABLED;
      return isEnabled;
    },
    isLoggedIn() {
      let user = store.getters['authStoreModule/getUser'];
      if (!user) {
        return false;
      } else {
        return true;
      }
    },
    trendingComponentsOrder() {
      //get the order of the components based on the current route. For "Home", its trendingSldeposts, trendingUsers, trending
      let order = [];
      let routeName = this.$route.name;
      switch (routeName) {
        case 'Newsfeed':
          order = ['TrendingSlydeposts', 'Trending', 'TrendingUsers'];
          break;
        case 'Lists':
          order = ['Trending'];
          break;
        case 'Search':
          order = ['TrendingSlydeposts', 'Trending', 'TrendingUsers'];
          break;
        case 'Community':
          order = ['TrendingUsers'];
          break;
        case 'Notifications':
          order = ['Trending', 'TrendingUsers'];
          break;
        case 'Settings':
          order = ['TrendingSlydeposts', 'TrendingUsers'];
          break;
        case 'Help':
          order = ['TrendingSlydeposts', 'TrendingUsers'];
          break;
        case 'Drafts':
          order = ['Trending', 'TrendingUsers'];
          break;
        case 'SingleList':
          order = ['Trending', 'TrendingSlydeposts'];
          break;
        case 'TrendingLists':
          order = [];
          break;
        case 'TrendingSlydeposts':
          order = [];
          break;
        case 'TrendingUsers':
          order = [];
          break;
        default:
          order = ['TrendingSlydeposts', 'TrendingUsers', 'Trending'];
          break;
      }
      return order;
    },
  },
};
</script>

<style>
.router-link-active {
  color: #30c3e3;
  text-decoration-line: underline;
}
</style>
